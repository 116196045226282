import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../utils/api";
import Loader from "../components/Loader";

export default function Gallery() {
  let { year } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noOfImages, setNoOfImages] = useState(10);

  useEffect(() => {
    const getImages = async () => {
      setLoading(true);
      const res = await fetch(`${BASE_URL}/competitions/${year}/gallery`);
      const json = await res.json();

      setImages(json.gallery[0]?.images);
      setLoading(false);
    };
    getImages();
  }, [year]);

  const seeMore = () => {
    setNoOfImages((prev) => prev + 10);
  };

  return (
    <main className="text-[#191919]">
      <h1 className="text-[#009A00] w-11/12 sm:w-5/6 mx-auto pb-10 leading-[31.68px] sm:leading-[100.32px] text-[24px] sm:text-[76px] font-bold">
        Felabration Through The Years {year}
      </h1>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-11/12 sm:w-5/6 mx-auto grid gap-5 grid-cols-1 sm:grid-cols-3">
          {images &&
            images.slice(0, noOfImages).map((image, index) => (
              <div key={index} id={index.toString()} className="img-loader">
                <img
                  loading="lazy"
                  onLoad={() => {
                    const container = document.getElementById(index.toString());
                    container?.classList.remove("img-loader");
                  }}
                  src={image}
                  alt={`Felabration ${year}`}
                  className="w-full h-auto rounded-lg shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
                />
              </div>
            ))}
        </div>
      )}
      {images?.length > noOfImages && (
        <div className="flex justify-center my-5 sm:my-8">
          <button
            onClick={seeMore}
            className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFFF00] text-white font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] rounded-full py-[6px] px-[31px] sm:px-[38px]">
            See more
          </button>
        </div>
      )}
    </main>
  );
}
