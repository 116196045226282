import { Link, useLocation, useNavigate } from "react-router-dom";

const NAV_LINKS = [
  { path: "/", name: "Home" },
  { path: "/about", name: "About" },
  { path: "/contact", name: "Contact" },
];

export default function Navbar({ handleToggle }: { handleToggle: () => void }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <nav className="bg-[#9BF99B99] z-[100000] backdrop-blur-3xl fixed top-12 w-11/12 sm:w-10/12 py-3 px-10 sm:px-20 rounded-full">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img
            loading="lazy"
            src="/icons/logo.svg"
            className="w-14 sm:w-28"
            alt="logo"
          />
        </Link>
        <div className="hidden sm:flex space-x-20">
          {NAV_LINKS.map(({ name, path }, index) => (
            <button
              key={index}
              onClick={() => navigate(path)}
              className={`text-[#494949] font-bold text-[24px] ${
                pathname === path ? "border-b-[4px] border-[#009A00]" : ""
              }`}>
              {name}
            </button>
          ))}
        </div>
        <button
          onClick={() => navigate("/host-felabration")}
          className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white rounded-full py-[25px] px-[37px] text-[24px] font-bold hidden sm:block">
          Host a Felabration
        </button>
        <img
          loading="lazy"
          alt="hamburger"
          src="/icons/hamburger.svg"
          onClick={handleToggle}
          className="w-9 block sm:hidden"
        />
      </div>
    </nav>
  );
}
