import { useEffect, useRef, useState } from "react";

interface Audio {
  coverImage: string;
  title: string;
  url: string;
}

export default function Song({ audio }: { audio: Audio }) {
  const [isPlaying, setIsPlaying] = useState<Boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }

    audioRef.current?.pause()
  }, [audioRef]);
  const onPlay = async () => {
    const playing = isPlaying
      ? audioRef.current?.pause()
      : await audioRef.current?.play();

    if (playing !== undefined) {
      console.log(playing);
    } else {
      console.log("nahh");
      setIsPlaying(!isPlaying);
    }
  };

  const getDuration = () => {
    if (audioRef.current) {
      const totalDuration = audioRef.current.duration;
      // const toHr = parseInt((totalDuration / 60 / 60).toString())
      const toMin = parseInt((totalDuration / 60).toString());
      const toSec = parseInt(totalDuration.toString());

      if (toSec > 59) {
        return `${toMin}:${toSec % 59}${(toSec % 59).toString().length === 1 ? "0" : ""
          }`;
      } else if (toMin && toSec) {
        return `${toMin}:${toSec}`;
      } else {
        return `0:00`;
      }
    } else {
      return `0:00`;
    }
  };

  return (
    <div className="flex space-x-5 sm:space-x-10 items-center">
      <div className="w-fit relative flex items-center justify-center">
        <img loading="lazy" src={"/icons/song.svg"}
          className="w-[51.6px] h-[57px] sm:w-[86px] sm:h-[95px] rounded-[10px]"
          alt={audio.title}
        />
        <button
          onClick={onPlay}
          className="w-[100%] flex items-center justify-center h-[100%] absolute top-0 bottom-0"
        >
          <img loading="lazy" alt="play"
            src={isPlaying ? "/icons/pause-icon.svg" : "/icons/play-icon.svg"}
            className="w-[50%]"
          />
        </button>
      </div>
      <div className="w-10/12">
        <div className="font-bold text-[16px] sm:text-[24px] leading-[24px] sm:leading-[36px]">
          {audio.title}
        </div>
        <div className="text-[#292D3299] leading-[21px] sm:leading-[30px] text-[14px] sm:text-[20px]">
          Fela Kuti
        </div>
      </div>
      <audio controls className="hidden" ref={audioRef} autoPlay>
        <source src={audio.url} type="audio/mpeg"></source>
      </audio>
      <div className="font-bold text-[16px] sm:text-[20px] leading-[18px] sm:leading-[30px] text-[#292D3299]">
        {getDuration()}
      </div>
    </div>
  );
}
