import { Carousel, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const competitions = [
  {
    images: ["dress_fela_1.jpeg", "dress_fela_1.jpeg"],
    link: "",
  },
  {
    images: ["afrobics_dance_1.jpg", "afrobics_dance_1.jpg"],
    link: "",
  },
  {
    images: ["artwork_competition_1.jpg", "artwork_competition_1.jpg"],
    link: "",
  },
];

const years = [
  {
    path: "/competitions/Felabration-2018-8045.jpg",
    year: 2018,
  },
  {
    path: "/competitions/FELABRATION-2019-WED-0451.jpg",
    year: 2019,
  },
  { path: "/competitions/PG_F18-5236.jpg", year: 2020 },
  {
    path: "/competitions/BUSHMAN-CREATIVES-F21-0448.jpg",
    year: 2021,
  },
  {
    path: "/competitions/PG_F18-5309.jpg",
    year: 2022,
  },
  {
    path: "/competitions/F23-ARTWORK-9504.jpeg",
    year: 2023,
  },
];

export default function Competition() {
  const navigate = useNavigate();

  return (
    <main className="space-y-12 sm:space-y-24">
      <section className="space-y-5 sm:space-y-12">
        <div className="w-11/12 sm:w-5/6 mx-auto">
          <Carousel className="w-full h-full" indicators={false}>
            {competitions.map(({ images, link }, index) => (
              <Carousel.Item key={index}>
                <div className="flex flex-col">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-5">
                    <Image
                      src={`/images/${images[0]}`}
                      className="rounded-3xl object-contain sm:max-h-[747px] w-full object-top"
                    />
                    <Image
                      src={`/images/${images[1]}`}
                      className="hidden md:block rounded-3xl object-contain sm:max-h-[747px] w-full object-top"
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      disabled
                      className={`bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white font-medium text-[16px] sm:text-[24px] leading-[31.76px] sm:leading-[47.64px] rounded-full py-[6px] px-[31px] sm:px-[38px]`}>
                      Register
                    </button>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <section className="">
        <div className="w-11/12 sm:w-5/6 mx-auto">
          <h2 className="text-[24px] leading-[36px] sm:text-[64px] font-medium sm:leading-[96px] my-8">
            Gallery
          </h2>
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-2">
            {years.map(({ year, path }, index) => (
              <div
                id={index.toString()}
                className="img-loader relative  flex justify-center items-center"
                key={index}>
                <img
                  onLoad={() => {
                    const container = document.getElementById(index.toString());
                    container?.classList.remove("img-loader");
                  }}
                  loading={"lazy"}
                  src={path}
                  className="h-[100%] w-[100%] shadow-lg dark:shadow-black/30 cover"
                  alt={`${year}`}
                />
                <button
                  onClick={() => navigate(`/gallery/${year}`)}
                  className="absolute uppercase border z-50 py-2 px-4 sm:py-[6px] sm:px-[31px] rounded-full font-[500] leading-[31.76px] sm:text-[16px] text-white">
                  View {year}
                </button>
                <div className="bg-[rgba(0,0,0,0.4)] absolute top-0 left-0 w-full h-full"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}
