import { useNavigate } from "react-router-dom";

export default function HostFelabration() {
  const navigate = useNavigate();
  return (
    <main className="sm:pt-0 sm:mt-[-250px] space-y-12 sm:space-y-24 text-[#191919]">
      <section className="flex items-center">
        <div className="w-1/2 hidden sm:block">
          <img
            loading="lazy"
            src="/images/host_a_felabration.jpeg"
            alt="host felabration"
            className="md:h-[900px]"
          />
        </div>
        <div className="px-10 w-full items-center sm:items-start sm:w-1/2 flex flex-col gap-3 sm:gap-5">
          <h1 className="font-semibold text-[32px] sm:text-[64px]">
            Host a{" "}
            <span className="text-[#009A00] font-crimson relative inline-block">
              Felabration{" "}
              <img
                loading="lazy"
                alt="star"
                src="/icons/star.svg"
                className="absolute top-[-7px] right-[-18px] sm:top-[-19px] w-8 sm:w-auto sm:right-[-50px]"
              />
            </span>
          </h1>
          <p className="text-[16px] sm:text-[24px] text-center md:text-left">
            Do you want to host a Felabration anywhere in the world or in
            Nigeria? Get a licence.
          </p>
          <div className="my-5 hidden sm:block">
            <a href="/doc/Partners.pdf" download>
              <button
                onClick={() => navigate("/host-felabration")}
                className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white hover:text-[#009A00] rounded-full py-[20px] sm:py-[25px] px-[30px] sm:px-[37px] text-[24px] font-bold transition duration-300 ease-in-out">
                Host a felabration
              </button>
            </a>
          </div>
          <div className="sm:hidden my-8">
            <img
              loading="lazy"
              src="/images/host_a_felabration.jpeg"
              alt="host felabration sm"
              className="rounded-3xl"
            />
          </div>
          <div className="my-5 sm:hidden">
            <a href="/doc/Partners.pdf" download>
              <button
                onClick={() => navigate("/host-felabration")}
                className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white rounded-full py-[20px] sm:py-[25px] px-[30px] sm:px-[37px] text-[24px] font-bold">
                Host a felabration
              </button>
            </a>
          </div>
        </div>
      </section>
      <section className="flex justify-center">
        <div className="w-11/12 sm:w-5/6 space-y-5 sm:space-y-12">
          <h2 className="text-[32px] leading-[44px] sm:leading-[96px] text-center sm:text-[64px] font-bold">
            Meet our Ambassadors
          </h2>
          <h3 className="text-center pb-8 text-[16px] sm:text-[24px] font-medium leading-[24px] sm:leading-[36px]">
            Media partner
          </h3>
          <div className="flex justify-center w-11/12 sm:w-5/6 mx-auto gap-y-10 gap-10">
            {[
              {
                id: crypto.randomUUID(),
                logo: "/logos/Plug orange hi-res (2).png",
              },
            ].map(({ logo, id }) => (
              <div
                key={id}
                className="flex justify-center items-center animate-bounce">
                <img
                  loading="lazy"
                  src={logo}
                  alt="sponsor"
                  className="w-16 sm:w-24 object-contain h-full"
                />
              </div>
            ))}
          </div>
          <h3 className="text-center pb-8 text-[16px] sm:text-[24px] font-medium leading-[24px] sm:leading-[36px]">
            Sponsors
          </h3>
          <div className="flex justify-center w-11/12 sm:w-5/6 mx-auto gap-y-10 gap-10">
            {[
              {
                id: crypto.randomUUID(),
                logo: "/logos/CHELSEA LOGO HI.jpg",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/Legend Logo.png",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/Legend Logo2blk.png",
              },
              {
                id: crypto.randomUUID(),
                logo: "/logos/Logo copy.png",
              },
            ].map(({ logo, id }) => (
              <div
                key={id}
                className="flex justify-center items-center animate-bounce">
                <img
                  loading="lazy"
                  src={logo}
                  alt="sponsor"
                  className="w-16 sm:w-24 object-contain  h-full"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}
