import { useLocation, useNavigate } from "react-router-dom";

const NAV_LINKS = [
  { path: "/", name: "Home" },
  { path: "/about", name: "About" },
  { path: "/contact", name: "Contact" },
];

export default function MobileNav({
  handleToggle,
}: {
  handleToggle: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      style={{
        background:
          "linear-gradient(180.75deg, #C7FFC7 -139.7%, #FFFFFF 99.35%)",
      }}
      className="h-full w-full z-[100000] flex gap-y-20 flex-col pt-12 items-center fixed left-0 top-0 transition-opacity duration-500 ease-in-out">
      <div className="flex justify-end px-10 w-full">
        <img
          loading="lazy"
          alt="close circle"
          onClick={handleToggle}
          src="/icons/close-circle.svg"
        />
      </div>
      <div className="flex flex-col gap-y-14">
        {NAV_LINKS.map(({ name, path }, index) => (
          <button
            key={index}
            onClick={() => {
              navigate(path);
              handleToggle();
            }}
            className={`text-[#000] font-semibold text-[24px] ${
              pathname === path ? "border-b-[4px] border-[#009A00] pb-2" : ""
            }`}>
            {name}
          </button>
        ))}
      </div>
      <button
        onClick={() => {
          navigate("/host-felabration");
          handleToggle();
        }}
        className="bg-gradient-to-r from-[#009A00] via-[#b9b345] to-[#FFD700] text-white rounded-full py-[17px] px-[31px] text-[24px] font-bold">
        Host a Felabration
      </button>
    </div>
  );
}
