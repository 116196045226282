import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Navbar from "./components/Navbar";
import { useState } from "react";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import HostFelabration from "./pages/host-felabration";
import Footer from "./components/Footer";
import MobileNavbar from "./components/MobileNav";
import Competition from "./pages/competition";
import Gallery from "./pages/gallery";

function App() {
  const [toggle, setToggle] = useState(false);
  const [width, setWidth] = useState(1440);

  const maxWidth = 1440;
  const maxSmallWidth = 400;
  const percentage = 100;

  let dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  let zoom =
    dimensions.width > 620
      ? (dimensions.width * percentage) / maxWidth
      : dimensions.width <= 400
      ? (dimensions.width * percentage) / maxSmallWidth
      : 100;

  function handleResize() {
    dimensions.width = window.innerWidth;
    setWidth(window.innerWidth);
    zoom =
      dimensions.width > 620
        ? (dimensions.width * percentage) / maxWidth
        : dimensions.width <= 400
        ? (dimensions.width * percentage) / maxSmallWidth
        : 100;
    // @ts-ignore
    document.body.style.zoom = zoom + "%";
  }

  // @ts-ignore
  document.body.style.zoom = zoom + "%";

  // change dimension on page resize
  window.addEventListener("resize", handleResize);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  return (
    <div className="bg-[#E4FFE4]">
      <div className="flex justify-center">
        <Navbar handleToggle={handleToggle} />
      </div>
      {toggle && (
        <div className="block overflow-y-hidden sm:hidden">
          <MobileNavbar handleToggle={handleToggle} />
        </div>
      )}
      <div className="pt-[150px] sm:pt-[250px] text-[#191919]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/host-felabration" element={<HostFelabration />} />
          <Route path="/competition" element={<Competition />} />
          <Route path="/gallery/:year" element={<Gallery />} />
        </Routes>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
