import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      className="flex mt-10 sm:mt-20 justify-center items-center py-10 sm:py-32 bg-center bg-cover"
      style={{ backgroundImage: "url('/images/footer-noise-texture.png')" }}
    >
      <div className="flex w-5/6 flex-col sm:flex-row gap-y-10 justify-between">
        {[
          {
            id: crypto.randomUUID(),
            title: "Home",
            links: [
              { id: crypto.randomUUID(), name: "Contact us", path: "/contact" },
              {
                id: crypto.randomUUID(),
                name: "Host a felabration",
                path: "/host-felabration",
              },
            ],
          },
          {
            id: crypto.randomUUID(),
            title: "About Us",
            links: [
              {
                id: crypto.randomUUID(),
                name: "Gallery",
                path: "/competition",
              },
              {
                id: crypto.randomUUID(),
                name: "Competitions",
                path: "/competition",
              },
              {
                id: crypto.randomUUID(),
                name: "Sponsors",
                path: "/host-felabration",
              },
            ],
          },
          {
            id: crypto.randomUUID(),
            title: "Contact",
            links: [
              {
                id: crypto.randomUUID(),
                name: "Twitter",
                path: "https://twitter.com/FelabrationNG?t=2uoQLs7RGKCXXAaP6QlmPw&s=09",
              },
              {
                id: crypto.randomUUID(),
                name: "Instagram",
                path: "https://www.instagram.com/felabrationng/",
              },
              {
                id: crypto.randomUUID(),
                name: "Facebook",
                path: "https://www.facebook.com/FelabrationNG?mibextid=9R9pXO",
              },
            ],
          },
        ].map(({ id, links, title }, index) => {
          return (
            <div className="flex flex-col gap-y-2 sm:gap-y-6" key={index}>
              <h3 className="font-bold text-[20px] sm:text-[32px] text-white leading-[30px] sm:leading-[48px]">
                {title}
              </h3>
              {links.map(({ name, id, path }) => (
                <Link
                  key={id}
                  to={path}
                  target="_blank"
                  className="text-[16px] sm:text-[20px] leading-[24px] sm:leading-[30px] font-bold text-white"
                >
                  {name}
                </Link>
              ))}
            </div>
          );
        })}
        <div className="flex flex-col gap-y-3 sm:gap-y-6 w-full sm:w-[366px]">
          <h3 className="font-bold text-[20px] sm:text-[32px] text-white leading-[30px] sm:leading-[48px]">
            Newsletter
          </h3>
          <input
            type="text"
            className="rounded-[5px] bg-[#FFFFFF99] h-[40px] sm:h-[50px] w-full px-6 text-xl"
          />
          <button className="bg-[#191919] rounded-[5px] text-white py-2 sm:py-3 text-[20px] font-bold sm:leading-[30px]">
            Subscribe
          </button>
        </div>
      </div>
    </footer>
  );
}
