import React from 'react'

function Loader() {
    return (
        <div className='flex items-center justify-center p-[50px]'>
            <div className='w-fit h-fit bg-[#fff] rounded-full p-[5px] shadow-lg'>
                <img loading="lazy" src="/images/fela.gif" className="loader w-14 sm:w-[120px] rounded-full" alt="logo" />
            </div>
        </div>
    )
}

export default Loader